import { MetaFunction, useNavigate } from '@remix-run/react';
import { ReactNode } from 'react';
import Footer from '~/components/layout/Footer';
import Header from '~/components/layout/Header';
import Layout from '~/components/layout/Layout';
import Main from '~/components/layout/Main';
import RobinButton from '~/components/robin/RobinButton';
import { IconName, renderIcon } from '~/components/robin/RobinIcons';
import RobinLogo from '~/components/robin/RobinLogo';
import { RobinBtnStyle } from '~/config/enums';

interface RobinPageProps {
  showLogo?: boolean;
  pageTitle: string;
  children: ReactNode;
  icon: IconName;
  buttonText: string;
  onButtonClick?: () => void;
}

export const meta: MetaFunction = (pageTitle) => {
  return [
    {
      title: `RobinFeed | ${pageTitle}`,
    },
  ];
};

export default function ErrorPage({ showLogo = true, children, buttonText, icon, onButtonClick }: RobinPageProps) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Layout>
      <Header>
        <RobinLogo />
      </Header>
      <Main>
        <div className="flex flex-col w-full h-full flex-grow space-y-6 px-4 pt-6 items-center justify-center overflow-y-hidden">
          <>
            {showLogo && renderIcon('logoGray', 28)}
            {children}
          </>
        </div>
      </Main>
      <Footer sticky={true}>
        <RobinButton iconName={icon} onClick={onButtonClick || goBack} btnStyle={RobinBtnStyle.Outline}>
          {buttonText}
        </RobinButton>
      </Footer>
    </Layout>
  );
}
